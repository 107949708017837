<script setup>
import { Link, router, usePage } from '@inertiajs/vue3';
import WebsiteLayout from '@/Layouts/WebsiteLayout.vue';
import Container from '@/Components/Shared/Container.vue';
import RestaurantAlert from '@/Components/Shared/Restaurant/RestaurantMessage.vue';
import CategoryNav from '@/Components/Shared/Restaurant/CategoryNav.vue';
import TimesButton from '@/Components/Shared/Restaurant/TimesButton.vue';
import CategoryBlock from '@/Components/Shared/Restaurant/CategoryBlock.vue';
import Cart from '@/Components/Shared/Restaurant/Cart.vue';
import { TransitionRoot } from '@headlessui/vue';
import { computed, onMounted, ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import { price } from '@/Helpers/formatters';
import TransportationDialog from '@/Components/Shared/Restaurant/TransportationDialog.vue';
import RestaurantHead from '@/Components/Shared/Restaurant/RestaurantHead.vue';
import Card from '@/Components/Shared/Cards/Card.vue';
import route from '@/Helpers/route';
import { UseWindowSize } from '@vueuse/components';
import RestaurantHeader from '@/Components/Shared/Restaurant/RestaurantHeader.vue';
import SearchBar from '@/Components/Shared/Restaurant/SearchBar.vue';
import HighlightSlider from '@/Components/Shared/Restaurant/HighlightSlider.vue';
import CrossSellDialog from '@/Components/Shared/Restaurant/CrossSellDialog.vue';

const page = usePage();

const props = defineProps({
    restaurant: Object,
    categories: Array,
    optionGroups: Array,
    cart: Object,
    search: String,
    schema: String,
    pages: Array,
    highlightedProducts: Array,
    crossSellProducts: Array,
});

const search = ref(props.search);

const showMenu = ref(false);
const showCart = ref(false);

const isMounted = ref(false);
const showTransportationDialog = computed(
    () =>
        isMounted &&
        props.cart.transportation === null &&
        props.cart.can_deliver_today &&
        props.cart.can_pickup_today,
);
onMounted(() => (isMounted.value = true));

const toggleMenu = () => (showMenu.value = !showMenu.value);
const toggleCart = () => (showCart.value = !showCart.value);

watch(
    search,
    debounce((value) => {
        router.get(
            route(`${page.props.routing_group}.menu`, {
                [page.props.routing_key]: page.props.routing_target,
            }),
            {
                search: value,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true,
            },
        );
    }, 150),
);

const visibility = ref(
    props.categories.reduce(
        (carry, current) => ({
            ...carry,
            [current.id]: false,
        }),
        {},
    ),
);

const currentCategory = computed(() =>
    Object.keys(visibility.value).find((key) => visibility.value[key] === true),
);

const isCrossSellDialogOpen = ref(false);
const setCrossSellDialogIsOpen = (value) => isCrossSellDialogOpen.value = value;
const showCrossSellDialog = () => setCrossSellDialogIsOpen(true);

</script>

<template>
    <RestaurantHead :restaurant="restaurant" :schema="schema" />

    <WebsiteLayout :restaurant="restaurant">
        <Container class="py-4">
            <RestaurantHeader
                :restaurant="restaurant"
                class="mb-4"
                dusk="menu-page"
                v-if="!restaurant.has_website"
            />

            <UseWindowSize v-slot="{ width }">
                <div
                    class="block xl:hidden grid grid-cols-3 gap-4 mb-4"
                    v-if="width < 1280"
                >
                    <TimesButton
                        title="Openingstijden"
                        :is-open="restaurant.is_open_now"
                        :times="restaurant.opening_times"
                        :exceptions="restaurant.opening_time_exceptions"
                        dusk="opening-times"
                    />

                    <TimesButton
                        title="Bezorgtijden"
                        :is-open="restaurant.can_deliver_now"
                        :times="restaurant.delivery_times"
                        :exceptions="restaurant.delivery_time_exceptions"
                        dusk="delivery-times"
                        v-if="restaurant.can_deliver"
                    />

                    <TimesButton
                        title="Afhaaltijden"
                        :is-open="restaurant.can_pickup_now"
                        :times="restaurant.pickup_times"
                        :exceptions="restaurant.pickup_time_exceptions"
                        dusk="pickup-times"
                        v-if="restaurant.can_pickup"
                    />

                    <Link
                        :href="
                            route(
                                `${page.props.routing_group}.reservations.create`,
                                { restaurant },
                            )
                        "
                        class="col-span-3 flex w-full items-center justify-center cursor-pointer font-medium rounded-xl transition ease-in-out duration-150 bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-600 disabled:bg-blue-300 px-2 py-2 xl:px-4 xl:py-4"
                        v-if="restaurant.accepts_reservations"
                        dusk="create-reservation"
                    >
                        Reserveren
                    </Link>
                </div>
            </UseWindowSize>

            <RestaurantAlert
                :message="message"
                class="shadow-md mb-4"
                v-for="message in restaurant.messages"
                :key="message.id"
            />

            <div
                class="grid grid-cols-1 xl:grid-cols-5 xl:gap-x-4 gap-y-4 mb-4"
            >
                <div class="hidden xl:block xl:col-span-1">
                    <div class="sticky top-[25px]">
                        <CategoryNav
                            :categories="categories"
                            :current-category="currentCategory"
                            class="mb-4"
                        />

                        <div class="grid grid-cols-1 gap-2">
                            <TimesButton
                                title="Openingstijden"
                                :is-open="restaurant.is_open_now"
                                :times="restaurant.opening_times"
                                :exceptions="restaurant.opening_time_exceptions"
                                dusk="opening-times"
                            />

                            <TimesButton
                                title="Bezorgtijden"
                                :is-open="restaurant.can_deliver_now"
                                :times="restaurant.delivery_times"
                                :exceptions="
                                    restaurant.delivery_time_exceptions
                                "
                                dusk="delivery-times"
                                v-if="restaurant.can_deliver"
                            />

                            <TimesButton
                                title="Afhaaltijden"
                                :is-open="restaurant.can_pickup_now"
                                :times="restaurant.pickup_times"
                                :exceptions="restaurant.pickup_time_exceptions"
                                dusk="pickup-times"
                                v-if="restaurant.can_pickup"
                            />

                            <Link
                                :href="
                                    route(
                                        `${page.props.routing_group}.reservations.create`,
                                        { restaurant },
                                    )
                                "
                                class="flex w-full items-center cursor-pointer font-medium justify-center 2xl:justify-normal rounded-xl transition ease-in-out duration-150 bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-600 disabled:bg-blue-300 px-2 py-2 md:px-4 md:py-4 mb-2"
                                v-if="restaurant.accepts_reservations"
                                dusk="create-reservation"
                            >
                                Reserveren
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="col-span-3">
                    <SearchBar
                        v-model="search"
                        :categories="categories"
                        :current-category="currentCategory"
                        class="mb-8"
                    />

                    <HighlightSlider
                        :products="highlightedProducts"
                        :option-groups="optionGroups"
                        :transportation="cart.transportation"
                        v-if="highlightedProducts.length > 0"
                    />

                    <template v-for="(category, index) in categories">
                        <CategoryBlock
                            :category="category"
                            :products="category.products"
                            :option-groups="optionGroups"
                            :lazy-load="index !== 0 && index !== 1"
                            :key="category.id"
                            :transportation="cart.transportation"
                            @visible="(id) => (visibility[id] = true)"
                            @invisible="(id) => (visibility[id] = false)"
                            v-if="category.products.length > 0"
                        />
                    </template>

                    <Card
                        class="w-2/3 bg-red-100! mx-auto"
                        v-if="search && categories.length === 0"
                    >
                        <div
                            class="flex flex-row justify-center gap-12 my-4 px-12 py-6"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                                class="w-12 h-12 fill-red-500"
                            >
                                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                                />
                            </svg>

                            <p class="font-bold text-2xl text-red-500 my-auto">
                                Geen producten gevonden
                            </p>
                        </div>
                    </Card>
                </div>

                <div class="hidden xl:block xl:col-span-1">
                    <div class="sticky top-[25px]">
                        <Cart
                            :cart="cart"
                            @toggle-cart="toggleCart"
                            :show-cross-sell-button="crossSellProducts.length > 0"
                            @show-cross-sell="showCrossSellDialog"
                        />
                    </div>
                </div>
            </div>

            <UseWindowSize v-slot="{ width }">
                <div
                    class="isolate xl:hidden fixed left-0 right-0 bottom-0 p-4 pt-12 bg-linear-to-t from-gray-500 transition-[padding-top] duration-250 z-50"
                    :class="{ 'pt-[80vh]': showMenu || showCart }"
                    v-if="width < 1280"
                >
                    <TransitionRoot
                        as="div"
                        class="fixed z-0 left-[1rem] right-[1rem] bottom-[4rem]"
                        :show="showMenu"
                        enter="transform transition duration-250"
                        enter-from="translate-y-48 opacity-0"
                        enter-to="translate-y-0 opacity-100"
                        leave="transform transition duration-250"
                        leave-from="translate-y-0 opacity-100"
                        leave-to="translate-y-48 opacity-0"
                    >
                        <CategoryNav
                            :categories="categories"
                            :current-category="currentCategory"
                            class="mb-2"
                            @toggle-menu="toggleMenu"
                        />
                    </TransitionRoot>

                    <TransitionRoot
                        as="div"
                        class="fixed z-0 left-[1rem] right-[1rem] bottom-[4rem]"
                        :show="showCart"
                        enter="transform transition duration-250"
                        enter-from="translate-y-48 opacity-0"
                        enter-to="translate-y-0 opacity-100"
                        leave="transform transition duration-250"
                        leave-from="translate-y-0 opacity-100"
                        leave-to="translate-y-48 opacity-0"
                    >
                        <Cart
                            :cart="cart"
                            @toggle-cart="toggleCart"
                            :show-cross-sell-button="crossSellProducts.length > 0"
                            @show-cross-sell="showCrossSellDialog"
                            class="mb-2"
                        />
                    </TransitionRoot>

                    <div class="relative grid grid-cols-3 gap-4 z-10">
                        <button
                            class="flex w-full h-12 justify-center items-center cursor-pointer rounded-xl transition ease-in-out duration-150 bg-sky-500 text-white hover:bg-sky-600 active:bg-sky-600 disabled:bg-sky-300 #border-2 #border-sky-600 font-medium tracking-wide shadow-xl"
                            @click="
                                showMenu = !showMenu;
                                showCart = false;
                            "
                        >
                            Menukaart
                        </button>

                        <button
                            class="col-span-2 flex w-full h-12 justify-center items-center cursor-pointer rounded-xl transition ease-in-out duration-150 bg-green-500 text-white hover:bg-green-600 active:bg-green-600 disabled:bg-green-300 #border-2 #border-green-600 font-medium tracking-wide shadow-xl"
                            @click="
                                showCart = !showCart;
                                showMenu = false;
                            "
                        >
                            Winkelmand<span v-if="cart.total > 0"
                                >: {{ price(cart.total) }}</span
                            >
                        </button>
                    </div>
                </div>
            </UseWindowSize>

            <TransportationDialog
                :can-deliver="cart.can_deliver_today"
                :can-pickup="cart.can_pickup_today"
                :accepts-reservations="restaurant.accepts_reservations"
                :show="showTransportationDialog"
            />

            <CrossSellDialog
                :products="crossSellProducts"
                :is-dialog-open="isCrossSellDialogOpen"
                :set-dialog-open="setCrossSellDialogIsOpen"
            />
        </Container>
    </WebsiteLayout>
</template>
